import React, { useState, useRef } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Link from "./shared/link";
import { Link as GatsbyLink } from "gatsby";
import media from "styled-media-query";
import { FaBars } from "react-icons/fa";
import useOnClickOutside from "../utils/useOnClickOutside";

const StyledHeader = styled.header`
  width: 100%;
  height: ${props => props.theme.headerHeight};
  position: fixed;
  top: 0;
  z-index: 1000;

  background: ${props => (props.filled ? props.theme.background : null)};
`;

const HeaderContent = styled.header`
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const HeaderNav = styled.nav`
  display: flex;
  z-index: -10;
  font-weight: 600;

  > * {
    margin: 0 2.5em;
  }

  ${media.lessThan("medium")`
    position: absolute;
    top: 0;
    right: 0;
    padding: 5em 1.5em 0.6em 1.5em;
    display: ${props => (props.showOnMobile ? "flex" : "none")};
    text-align: right;
    flex-direction: column;
    align-items: flex-end;

    background: ${props => props.theme.background};
  `}
`;

const HeaderLink = styled(Link)`
  margin: 0 2.5em;
  letter-spacing: 2.8px;

  ${media.lessThan("medium")`
    margin: 0.5em 0;
  `}
`;

const StyledBurger = styled.button`
  margin: auto 1em;

  background: transparent;
  border: none;
  color: white;

  &:focus {
    outline: none;
  }

  ${media.greaterThan("medium")`
    display: none;
  `}
`;

const Burger = ({ onClick }) => (
  <StyledBurger onClick={onClick}>
    <FaBars size="2em" />
  </StyledBurger>
);

const HeaderImgWrapper = styled(GatsbyLink)`
  max-height: 100%;
  width: 100%;
  height: 100%;
  margin: auto 0;
  margin-left: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-height: 68%;
    height: 100%;
    width: 100%;
  }
`;

const Header = ({ filled }) => {
  const data = useStaticQuery(HeaderQuery);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const burgerRef = useRef();
  useOnClickOutside(burgerRef, () => setShowMobileNav(false));

  return (
    <>
      <StyledHeader filled={filled}>
        <HeaderContent>
          {filled && (
            <HeaderImgWrapper to="/">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: "left center",
                }}
              />
            </HeaderImgWrapper>
          )}
          <div ref={burgerRef}>
            <HeaderNav showOnMobile={showMobileNav}>
              <HeaderLink to="/">HOME</HeaderLink>
              {/* <HeaderLink to="/info">FEATURES</HeaderLink> */}
              <HeaderLink
                as="a"
                href="https://www.indiedb.com/games/the-last-cube"
                target="_black"
                rel="noopener"
              >
                DEVBLOG
              </HeaderLink>
              <HeaderLink to="https://discord.gg/cNFt8Mb">DISCORD</HeaderLink>
              <HeaderLink to="http://presskit.lastcubegame.com/">
                PRESSKIT
              </HeaderLink>
            </HeaderNav>
            <Burger onClick={() => setShowMobileNav(s => !s)} />
          </div>
        </HeaderContent>
      </StyledHeader>
    </>
  );
};

export default Header;

const HeaderQuery = graphql`
  query {
    site {
      siteMetadata {
        product
      }
    }
    logo: file(relativePath: { eq: "tlc-logo-hor.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
