import React from "react";

import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import Header from "../components/header";
import SectionWrapper from "../components/shared/section-wrapper";
import SectionContent from "../components/shared/section-content";
import styled from "styled-components";

const StyledPp = styled.div`
  margin-top: ${props => props.theme.headerHeight};
  min-height: 100vh;
  color: ${p => p.theme.main};
  text-align: left;

  font-size: 1.3rem;
  line-height: 1.5rem;
`;

const PrivacyPolicy = () => (
  <Layout>
    <SectionWrapper>
      <SectionContent>
        <SEO title="Terms of Service" />
        <Header filled />
        <StyledPp>
          <h1>The Last Cube - Privacy Policy</h1>
          <p>Created: July 20th, 2020</p>
          <p>
            This Privacy Policy explains how Improx Games (“we” or “us”)
            collects and uses data collected in-game from the players of The
            Last Cube on all platforms (PC and gaming consoles). We are a
            limited liability company named Improx Games registered under
            Finnish Law with the Business Identity Code 2766584-6. Not all
            platforms necessarily collect all the data mentioned below at all
            times. You can contact us with contact@improxgames.com.
          </p>
          <h2>1. Why do we collect data</h2>
          <p>
            We collect data about the user’s in-game behavior, as well as
            optional feedback from players, in order to develop and improve The
            Last Cube. This data helps us measure the quality of the game from
            gameplay and technical perspectives. For instance, we may learn
            which are the moments in the game where players most often quit or
            get stuck. We may use this information to improve the in-game
            puzzles and content for all players. The Last Cube does not collect
            data for advertising or personalization purposes.
          </p>
          <h2>2. What data to we collect</h2>
          <h3>2.1. In-game analytics</h3>
          <p>
            We use Unity's Analytics service to get information on players'
            progress and behavior through the game. For example, we may learn
            how long it takes for players to complete a puzzle, which in-game
            items players have collected, in which places players quit or lost
            focus from the game application. We may additionally collect data
            regarding players’ technical setup, such as screen aspect ratios and
            graphics processing units. We may also collect data on game crashes
            and bugs. All this information is anonymous, and we do not learn
            about the identities of players through in-game analytics. If you
            don't want to contribute any of this information, then please don't
            continue playing.
          </p>

          <h3>2.2. Player feedback</h3>
          <p>
            Players may be able to deliberately send us feedback through an
            in-game form to report bugs, ideas or other feedback. In the form,
            the player chooses the nature of the feedback, writes a text comment
            and may optionally include a screenshot of their game at the moment.
            For each received feedback entry, we additionally receive
            information on where the player is located inside the game world,
            what the player’s progress in the current level is and what is the
            date and time of the feedback. This information is sent to our
            servers, allowing us to process it. Through this feedback form, we
            do not learn or save the identity or any personal information of the
            player. We do not even know whether two feedback forms were sent by
            the same person or device. We collect and manage this feedback in
            Google Firebase.
          </p>

          <h2>3. Handling your data</h2>
          <p>
            Our hosting services may have access to the data we collect. In
            addition, Improx Games’ possible subsidiaries and partners may also
            have access to the data we collect. Furthermore, government
            authorities may ask for the data we collect, where disclosure is
            necessary.
          </p>
        </StyledPp>
      </SectionContent>
    </SectionWrapper>
  </Layout>
);

export default PrivacyPolicy;
