import { Link } from "gatsby";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: ${props => props.theme.inactive};
  text-decoration: none;

  :hover {
    color: ${props => props.theme.main};
  }
`;

export default StyledLink;
